import React, { useState } from "react";
import UploadAssessmentInput from "./icons/Upload Assessment Inputs.svg";
import ClusterNodeMetrics from "./icons/Cluster Node Metrics.svg";
import ShareIndexAnalysis from "./icons/Shared Index Analysis.svg";
import LoadTestingMetrics from "./icons/Load Testing Metrics.svg";
import SelectTargetDatabase from "./icons/Select Target Database.svg";
import TargetConfiguration from "./icons/Upload Assessment Inputs-2.svg";
import SelecttargetInfra from "./icons/Target Configuration - Infrastructure.svg";
import SelecttargetEngine from "./icons/Target Configuration - Engine Optimization.svg";
import SelecttargetTotalCost from "./icons/Target Total Cost of Ownership.svg";
import TargetTerraform from "./icons/Target Terraform Deployment Code.svg";
import DataMigrationIcon from "./icons/Data Migration.svg";
import DataQualityCheck from "./icons/Data Quality Check.svg";
import CutOverPlanning from "./icons/Cut Over Planning.svg";
const Filter = ({ onDashboardSelect }) => {
  const [selectedDashboard, setSelectedDashboard] = useState(null);

  const handleDashboardSelect = (dashboard) => {
    setSelectedDashboard(dashboard);
    onDashboardSelect(dashboard);
  };

  return (
    <aside className="sidebar h-full min-w-full font-poppins">
      <section className="sidebar-content h-fit min-h-[20rem] overflow-visible p-1">
        <nav className="menu rounded-md">
          <section className="menu-section px-4">
            <section className="sidebar-title items-center ">
              <span className="menu-title font-poppins custom-h5">
                Assessments
              </span>
            </section>
            <ul className="menu-items">
              <li className="menu-item flex flex-col items-start ">
                <div className="flex">
                  <img
                    src={UploadAssessmentInput}
                    className="h-5 w-5 mr-2"
                  ></img>
                  Upload Assessment Inputs
                </div>
                <input
                  type="file"
                  accept=".zip"
                  class="input-file input-file-xs w-2/3  items-start justify-start"
                />
              </li>
              <li className="menu-item active">
                <img src={ClusterNodeMetrics} className="h-5 w-5"></img>
                <button onClick={() => handleDashboardSelect("dashboard1")}>
                  Cluster Node Metrics
                </button>
              </li>

              <li className="menu-item">
                <img src={ShareIndexAnalysis} className="h-5 w-5"></img>
                <button onClick={() => handleDashboardSelect("dashboard2")}>
                  Share Index Analysis
                </button>
              </li>
              <li className="menu-item">
                <img src={LoadTestingMetrics} className="h-5 w-5"></img>
                <button onClick={() => handleDashboardSelect("dashboard3")}>
                  Load Testing Metrics
                </button>
              </li>
            </ul>
            <div className="divider my-0"></div>
          </section>

          <section className="menu-section px-4">
            <section className="sidebar-title items-center ">
              <span className="menu-title font-poppins custom-h5 opacity-70">
                Migration Planning
              </span>
            </section>
            <ul className="menu-items">
              <li className="menu-item" aria-disabled>
                <img src={SelectTargetDatabase} className="h-5 w-5"></img>
                <button
                  onClick={() =>
                    handleDashboardSelect("select-target-database")
                  }
                >
                  Select Target Database
                </button>
              </li>
              <li className="menu-item opacity-30">
                <img src={SelecttargetInfra} className="h-5 w-5"></img>
                <span>Target Configuration - Infrastructure</span>
              </li>

              <li className="menu-item opacity-30">
                <img src={SelecttargetEngine} className="h-5 w-5"></img>
                <span>Target Configuration - Engine Optimisation</span>
              </li>
              <li className="menu-item opacity-30">
                <img src={SelecttargetTotalCost} className="h-5 w-5"></img>
                <span>Target Total Cost of Ownership</span>
              </li>
            </ul>
            <div className="divider my-0"></div>
          </section>

          <section className="menu-section px-4">
            <section className="sidebar-title items-center ">
              <span className="menu-title font-poppins custom-h5 opacity-70">
                Migration Execution
              </span>
            </section>
            <ul className="menu-items">
              <li className="menu-item opacity-30" aria-disabled>
                <img src={TargetTerraform} className="h-5 w-5"></img>
                Target Terraform Deployment Code
              </li>
              <li className="menu-item opacity-30">
                <img src={DataMigrationIcon} className="h-5 w-5"></img>
                <button>Data Migration</button>
              </li>

              <li className="menu-item opacity-30 flex items-start justify-start">
                <img src={DataQualityCheck} className="h-5 w-5"></img>
                <button>Data Quality Check</button>
              </li>
              <li className="menu-item opacity-30">
                <img src={CutOverPlanning} className="h-5 w-5"></img>
                <button>Cut Over Planning</button>
              </li>
            </ul>
            <div className="divider my-0"></div>
          </section>
        </nav>
      </section>
    </aside>
  );
};

export default Filter;
