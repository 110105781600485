import React, { useState } from "react";
import Navbar from "./components/Navbar";
import Dashboard1 from "./components/dashboard1";
import Dashboard2 from "./components/dashboard2";
import Dashboard3 from "./components/dashboard3";
import Filter from "./components/filter"; // Correct import path
import "./customstyles.css";
import TargetConfig from "./components/TargetConfig";

function App() {
  const [selectedDashboard, setSelectedDashboard] = useState("dashboard1"); // Initial Dashboard load

  const handleDashboardSelect = (dashboard) => {
    setSelectedDashboard(dashboard);
  };

  return (
    <div className="App">
      <div className="font-poppins h-screen">
        <Navbar />
        <div className="flex flex-1">
          <div className="flex flex-col h-full w-1/5 bg-gray-100">
            <Filter onDashboardSelect={handleDashboardSelect} />
          </div>
          <div className="flex-1 overflow-y-auto bg-white">
            {console.log(selectedDashboard)}
            {/* Conditional Rendering */}
            {selectedDashboard === "dashboard1" && <Dashboard1 />}
            {selectedDashboard === "dashboard2" && <Dashboard2 />}
            {selectedDashboard === "dashboard3" && <Dashboard3 />}
            {selectedDashboard === "select-target-database" && <TargetConfig />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

// import React, { useState } from "react";
// import Dashboard from "./components/Dashboard";
// import Filter from "./components/filter";
// import Upload from "./components/Upload";
// import "./customstyles.css";

// function App() {
//   const [loading, setLoading] = useState(false);
//   const [uploadSuccess, setUploadSuccess] = useState(false);

//   const handleUploadSuccess = () => {
//     setUploadSuccess(true);
//     setLoading(false);
//   };

//   return (
//     <div className="App">
//       <div className="font-poppins h-screen">
//         {!uploadSuccess && (
//           <div className="flex justify-center items-center h-screen">
//             <div className="login-page">
//               <Upload
//                 setLoading={setLoading}
//                 handleUploadSuccess={handleUploadSuccess}
//               />
//             </div>
//           </div>
//         )}
//         {true && (
//           <div className="flex flex-1">
//             <div className="flex flex-col h-full w-1/5 bg-gray-100">
//               <Filter />
//             </div>
//             <div className="flex-1 overflow-y-auto bg-white">
//               <Dashboard />
//             </div>
//           </div>
//         )}
//         {loading && <p>Loading...</p>}
//       </div>
//     </div>
//   );
// }

// export default App;
