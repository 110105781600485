import React from "react";
import AedeonLogo from "./aedeon_logo.png";
import UserIcon from "./user.png";

function Navbar() {
  return (
    <nav className="flex items-center justify-between flex-wrap bg-very-dark-blue p-4">
      {/* Left side with Aedeon logo */}
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <img
          src={AedeonLogo}
          alt="Aedeon Logo"
          className="fill-current h-16 w-64"
        />
      </div>

      {/* Right side with profile picture and sign-out button */}
      <div className="flex items-center">
        <img
          src={UserIcon}
          alt="Profile"
          className="h-8 w-8 rounded-full mr-5 ring-2 ring-white"
        />
        <button className="text-white hover:text-gray-800 hover:bg-white border border-white px-4 py-2 rounded mr-5">
          Sign Out
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
