// src/TargetConfig.js
import React, { useState } from "react";
import StandardCreate from "./sub-component/StandardCreate";
import EasyCreate from "./sub-component/EasyCreate";

function TargetConfig() {
  const [formData, setFormData] = useState({
    domainName: "",
    domainCreation: "Easy Create",
    engineVersion: "OpenSearch_2.11",
    networkAccess: "VPC",
    ipAddressType: "Dual Stack",
    enableFineGrainedAccessControl: false,
    masterUserOption: "IAM ARN",
    iamArn: "",
    masterUsername: "",
    masterPassword: "",
    Templates: "",
    Standby: "yes" | "no",
    enableOlderVersion: false,
    enableCompatibilty: false,
    instanceFamily: "",
    instanceType: "",
    numberOfNodes: 3,
    storageType: "",
    volumeType: "",
    sizeofNode: 10,
    totalIOPS: 3000,
    totalThroughput: 125,
    dedicatedInstanceType: "",
    domainAccessPolicy: "fine-grained",
    encryptionKey: "aws-owned",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleGenerateJson = (e) => {
    e.preventDefault();
    window.alert(JSON.stringify(formData));
  };

  return (
    <div>
      <section className="mx-10 my-10 ">
        <div className="custom-h4">Create Domain</div>
        <div className="p-8 shadow-lg">
          <form className="flex flex-col gap-3">
            <div className="w-full flex flex-col border-2 p-2">
              <h6 className="custom-body-large-bold">Domain Name</h6>
              <label className="sr-only" htmlFor="name">
                Domain Name
              </label>
              <input
                className="input rounded-none border mt-2 max-w-md"
                placeholder="Name"
                type="text"
                id="name"
                name="domainName"
                value={formData.domainName}
                onChange={handleInputChange}
              />
            </div>

            <div className="border-2 p-2">
              <h3 className="my-1 custom-body-large-bold">
                Domain Creation Method
              </h3>
              <div className="grid sm:grid-cols-2">
                <div>
                  <div className="flex flex-col border p-2 gap-2 mr-2">
                    <div className="flex">
                      <input
                        type="radio"
                        className="radio-solid radio mr-2"
                        checked={formData.domainCreation === "Easy Create"}
                        onChange={() =>
                          handleInputChange({
                            target: {
                              name: "domainCreation",
                              value: "Easy Create",
                              type: "radio",
                            },
                          })
                        }
                      />
                      <span className="w-full">Easy Create</span>
                    </div>
                    <div>
                      <span className="custom-body-small">
                        Quickly create an OpenSearch domain using 'Multi-AZ with
                        Standby' for high availability. You can change some
                        configuration options after the domain is created.
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex flex-col border p-2 gap-2 mr-2">
                    <div className="flex">
                      <input
                        type="radio"
                        className="radio-solid radio mr-2"
                        checked={formData.domainCreation === "Standard Create"}
                        onChange={() =>
                          handleInputChange({
                            target: {
                              name: "domainCreation",
                              value: "Standard Create",
                              type: "radio",
                            },
                          })
                        }
                      />
                      <span className="w-full">Standard Create</span>
                    </div>
                    <div>
                      <span className="custom-body-small">
                        Create an OpenSearch domain with your preferred
                        configuration. You can choose all configuration options,
                        including availability zone(s), instances and storages,
                        and security configurations.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {formData.domainCreation === "Easy Create" ? (
              <EasyCreate
                formData={formData}
                handleInputChange={handleInputChange}
              />
            ) : (
              <StandardCreate
                formData={formData}
                handleInputChange={handleInputChange}
              />
            )}

            <button
              className="bg-blue-500 hover:bg-blue-700 w-1/4 m-auto text-white font-bold py-2 px-4 rounded"
              onClick={handleGenerateJson}
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default TargetConfig;
