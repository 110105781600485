// src/sub-component/StandardCreate.js
import React from "react";

function StandardCreate({ formData, handleInputChange }) {
  const totalNodes = formData.numberOfNodes;
  const baseNodesPerRow = Math.floor(totalNodes / 3);
  const remainderNodes = totalNodes % 3;

  const rows = [];
  for (let i = 0; i < 3; i++) {
    const nodesInThisRow = baseNodesPerRow + (i < remainderNodes ? 1 : 0);
    rows.push(
      <tr key={i}>
        <th>
          AZ<sup>{i + 1}</sup>
        </th>
        <td>{nodesInThisRow}</td>
        <td>{nodesInThisRow * formData.sizeofNode}</td>
      </tr>
    );
  }
  return (
    <div className="flex flex-col gap-3 ">
      <div className="border-2 p-2 Template-class">
        <h3 className="my-1 custom-body-large-bold">Templates</h3>
        <span className="custom-body-small">
          Templates specify common settings for your use case. After creating
          the domain, you can change these settings at any time.
        </span>
        <div className="grid sm:grid-cols-2">
          <div>
            <div className="flex flex-col border p-2 gap-2 mr-2">
              <div className="flex">
                <input
                  type="radio"
                  className="radio-solid radio mr-2"
                  checked={formData.Templates === "Production"}
                  onChange={() =>
                    handleInputChange({
                      target: {
                        name: "Templates",
                        value: "Production",
                        type: "radio",
                      },
                    })
                  }
                />
                <span className="w-full">Production </span>
              </div>
              <div>
                <span className="custom-body-small">
                  Use for production workloads for high-availability and
                  performance. Defaults follow the recommended best practices
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col border p-2 gap-2 mr-2">
              <div className="flex">
                <input
                  type="radio"
                  className="radio-solid radio mr-2"
                  checked={formData.Templates === "Dev/Test"}
                  onChange={() =>
                    handleInputChange({
                      target: {
                        name: "Templates",
                        value: "Dev/Test",
                        type: "radio",
                      },
                    })
                  }
                />
                <span className="w-full">Dev/Test</span>
              </div>
              <div className="">
                <span className="custom-body-small">
                  Use for development or testing Amazon OpenSearch Service
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-2 p-2 Deployment-class">
        <h3 className="my-1 custom-body-large-bold">Deployment Options</h3>
        <span className="custom-body-small">
          Select a deployment option that corresponds to the availability goals
          for your application.
        </span>
        <div className="grid sm:grid-cols-2">
          <div>
            <div className="flex flex-col border p-2 gap-2 mr-2">
              <div className="flex">
                <input
                  type="radio"
                  className="radio-solid radio mr-2"
                  checked={formData.Standby === "yes"}
                  onChange={() =>
                    handleInputChange({
                      target: {
                        name: "Standby",
                        value: "yes",
                        type: "radio",
                      },
                    })
                  }
                />
                <span className="w-full">Domain with Standby </span>
              </div>
              <div>
                <span className="custom-body-small">
                  Nodes in one of the Availability Zone (AZ) are reserved as
                  standby. Automatic failover to standby. Provides 99.99%
                  availability and consistent performance. (Recommended)
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col border p-2 gap-2 mr-2">
              <div className="flex">
                <input
                  type="radio"
                  className="radio-solid radio mr-2"
                  checked={formData.Standby === "no"}
                  onChange={() =>
                    handleInputChange({
                      target: {
                        name: "Standby",
                        value: "no",
                        type: "radio",
                      },
                    })
                  }
                />
                <span className="w-full">Domain without Standby</span>
              </div>
              <div>
                <span className="custom-body-small">
                  Nodes are distributed across Availability Zone(s).
                  Availability depends on the number of AZs and copies of data.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="border-2 p-2 EngineOptions-class">
        <h3 className="my-1 custom-body-large-bold">Engine Options</h3>
        <div className="border p-2">
          <h4>Version</h4>
          <div className="dropdow w-1/3">
            <select
              className="select"
              name="engineVersion"
              value={formData.engineVersion}
              onChange={handleInputChange}
            >
              <option>2.13(latest)</option>
              <option>1.3</option>
            </select>
          </div>
          <span className="custom-body-small">
            Certain features require specific OpenSearch/Elasticsearch versions.
            We recommend choosing the latest version.
          </span>
          <div>
            <label className="flex cursor-pointer gap-2 my-3">
              <input
                type="checkbox"
                className="switch switch-ghost-primary"
                name="enableOlderVersion"
                checked={formData.enableOlderVersion}
                onChange={handleInputChange}
              />
              <span>Include Older Versions</span>
            </label>
          </div>
          <div>
            <label className="flex cursor-pointer gap-2 ">
              <input
                type="checkbox"
                className="checkbox"
                name="enableCompatibilty"
                checked={formData.enableCompatibilty}
                onChange={handleInputChange}
              />
              <span>Enable Compatibility Mode</span>
            </label>
            <span className="custom-body-small">
              Certain Elasticsearch OSS clients, such as Logstash, check the
              cluster version before connecting. Compatibility mode sets
              OpenSearch to report its version as 7.10 so that these clients
              continue to work with the service.
            </span>
          </div>
        </div>
      </div>

      <div className="border-2 p-2 DataNode-class">
        <h3 className="my-1 custom-body-large-bold">Data Nodes</h3>
        <span className="custom-body-small">
          Select an instance family and type that corresponds to the compute,
          memory, and storage needs of your application. Consider the size of
          your indices, number of shards and replicas, type of queries, and
          volume of requests.
        </span>
        <div className="grid sm:grid-cols-2">
          <div className="DataNodeConfig-class">
            <div className="flex flex-col border p-2 gap-2 mr-2">
              <div className="border p-1">
                <h4>Instance Family</h4>
                <div className="dropdow w-1/2">
                  <select
                    className="select"
                    name="instanceFamily"
                    value={formData.instanceFamily}
                    onChange={handleInputChange}
                  >
                    <option>OR1</option>
                    <option>General Purpose</option>
                    <option>Compute Optimised</option>
                    <option>Memory Optimised</option>
                    <option>Storage Optimised</option>
                  </select>
                </div>
              </div>
              <div className="border p-1">
                <h4>Instance type</h4>
                <div className="dropdown w-1/2">
                  <select
                    className="select"
                    name="instanceType"
                    value={formData.instanceType}
                    onChange={handleInputChange}
                  >
                    <label>M5</label>
                    <option>m5.large.search</option>
                    <option>m5.xlarge.search</option>
                    <option>m5.2xlarge.search</option>
                    <option>m5.3xlarge.search</option>
                    <label>M6G (Graviton 2)</label>
                    <option>m6g.large.search </option>
                    <option>m6g.xlarge.search </option>
                    <option>m6g.2xlarge.search </option>
                    <option>m6g.3xlarge.search </option>
                  </select>
                </div>
              </div>
              <div className="border p-1">
                <h4>Number of Nodes</h4>
                <span className="custom-body-small">
                  For three Availability Zones, we recommend instances in
                  multiples of three for equal distribution across the
                  Availability Zones.
                </span>
                <div>
                  <input
                    className="input"
                    type="number"
                    name="numberOfNodes"
                    value={formData.numberOfNodes}
                    onChange={handleInputChange}
                    max={80}
                    min={1}
                  ></input>
                </div>
                <h6 className=" text-xs">
                  The number must be between 1 and 80.
                </h6>
              </div>
              <div className="border p-1">
                <h4>Storage Type</h4>
                <span className="custom-body-small">
                  Choose a storage type for your data nodes.
                </span>
                <div className="dropdow w-1/2">
                  <select
                    className="select"
                    name="storageType"
                    value={formData.storageType}
                    onChange={handleInputChange}
                  >
                    <option>EBS</option>
                  </select>
                </div>
              </div>

              <div className="border p-1">
                <h4>EBS Volume Type</h4>
                <span className="custom-body-small">
                  EBS volumes enable you to independently scale the storage
                  resources of your domain from its compute resources. EBS
                  volumes are most useful for domains with very large data sets,
                  but without the need for large compute resources.
                </span>
                <div className="dropdow w-1/2">
                  <select
                    className="select"
                    name="volumeType"
                    value={formData.volumeType}
                    onChange={handleInputChange}
                  >
                    <option>General Purpose(SSD) -gp3</option>
                    <option>General Purpose(SSD) -gp2</option>
                  </select>
                </div>
              </div>

              <div className="border p-1">
                <h4>EBS Storage size per Nodes</h4>
                <div>
                  <input
                    className="input"
                    type="number"
                    name="sizeofNode"
                    value={formData.sizeofNode}
                    onChange={handleInputChange}
                    max={2048}
                    min={10}
                  ></input>
                </div>
                <h6 className=" text-xs">
                  The number must be between 10 and 2048.
                </h6>
              </div>
            </div>
          </div>
          <div className="dataNodeTable-class">
            <div className="table-container">
              <table className="table-zebra table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Nodes</th>
                    <th>Total storage (GB)</th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                  <tr>
                    <th>AZ</th>
                    <td>{totalNodes}</td>
                    <td>{formData.sizeofNode * totalNodes}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="AdvanceVolume-class border p-2 mt-2">
          <div>
            <h4>Total IOPs per node</h4>
            <input
              className="input"
              type="number"
              value={formData.totalIOPS}
              name="totalIOPS"
              onChange={handleInputChange}
            ></input>
            IOPs
            <h6 className="text-xs">
              The total IOPS to volume size should not exceed 500:1
            </h6>
          </div>
          <div>
            <h4>Total throughput(MIB/s) per node</h4>
            <input
              className="input"
              type="number"
              value={formData.totalThroughput}
              name="totalThroughput"
              onChange={handleInputChange}
            ></input>
            MIB/s
            <h6 className="text-xs">
              The total throughput to IOPS ratio should not exceed 1:4
            </h6>
          </div>
        </div>
      </div>

      <div className="border-2 p-2 DedicatedMaster-class">
        <h3 className="my-1 custom-body-large-bold">Dedicated master Nodes</h3>
        <span className="custom-body-small">
          Dedicated master nodes improve the stability of your domain. For
          production domains, three is recommended.
        </span>
        <div className="grid sm:grid-cols-2">
          <div className="DataNodeConfig-class">
            <div className="flex flex-col border p-2 gap-2 mr-2">
              <div className="border p-1">
                <h4>Instance type</h4>
                <div className="dropdown w-1/2">
                  <select
                    className="select"
                    name="dedicatedInstanceType"
                    value={formData.dedicatedInstanceType}
                    onChange={handleInputChange}
                  >
                    <label>C6G</label>
                    <option>c6g.large.search</option>
                    <option>c6g.xlarge.search</option>
                    <option>c6g.2xlarge.search</option>
                    <option>c6g.3xlarge.search</option>
                    <label>M6G (Graviton 2)</label>
                    <option>m6g.large.search </option>
                    <option>m6g.xlarge.search </option>
                    <option>m6g.2xlarge.search </option>
                    <option>m6g.3xlarge.search </option>
                    <label>R6G (Graviton 2)</label>
                    <option>r6g.large.search </option>
                    <option>r6g.xlarge.search </option>
                    <option>r6g2xlarge.search </option>
                    <option>r6g.3xlarge.search </option>
                  </select>
                </div>
              </div>
              <div className="border p-1">
                <h4>Number of Nodes</h4>
                <span className="custom-body-small">
                  For three Availability Zones, we recommend instances in
                  multiples of three for equal distribution across the
                  Availability Zones.
                </span>
                <div>
                  <input
                    class="input"
                    placeholder="Disabled"
                    disabled
                    name="numberOfNodes"
                    value={formData.numberOfNodes}
                    onChange={handleInputChange}
                    max={80}
                    min={1}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="dataNodeTable-class">
            <div className="table-container">
              <table className="table-zebra table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Nodes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AZ</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>AZ</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>AZ</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <td>3</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="border-2 p-2 VPC-class">
        <h3 className="my-1 custom-body-large-bold">Network</h3>
        <div className="my-2">
          <label className="flex cursor-pointer gap-2">
            <input
              type="radio"
              className="radio"
              checked={formData.networkAccess === "VPC"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "networkAccess",
                    value: "VPC",
                    type: "radio",
                  },
                })
              }
            />
            <span>VPC access - recommended</span>
          </label>
          <label className="flex cursor-pointer gap-2">
            <input
              type="radio"
              className="radio"
              checked={formData.networkAccess === "Public"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "networkAccess",
                    value: "Public",
                    type: "radio",
                  },
                })
              }
            />
            <span>Public access</span>
          </label>
        </div>
        <p className="custom-body-medium">IP address type</p>
        <span className="custom-body-small text-gray-700">
          Select the type of IP address that your subnets use.
        </span>
        <div className="grid sm:grid-cols-2 my-2">
          <div className="flex border p-2 gap-2 mr-2">
            <input
              type="radio"
              className="radio-solid radio"
              checked={formData.ipAddressType === "Dual Stack"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "ipAddressType",
                    value: "Dual Stack",
                    type: "radio",
                  },
                })
              }
            />
            <span className="w-full">Dual Stack Mode</span>
          </div>
          <div className="flex border p-2 gap-2">
            <input
              type="radio"
              className="radio-solid radio"
              checked={formData.ipAddressType === "IPv4 only"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "ipAddressType",
                    value: "IPv4 only",
                    type: "radio",
                  },
                })
              }
            />
            <span className="w-full">IPv4 only</span>
          </div>
        </div>
        <p className="custom-body-medium">VPC</p>
        <span className="custom-body-small text-gray-700">
          To use dual-stack, make sure that you associate an IPv6 CIDR block
          with a subnet in the VPC you specify.
        </span>
        <div className="my-2">
          <select className="select">
            <option>VPC ID</option>
          </select>
        </div>
      </div>
      {/* Additional fields for Easy Create can be added here */}
      <div className="border-2 p-2 FineAccess-class">
        <h3 className="my-1 custom-body-large-bold">
          Fine-grained access control
        </h3>
        <span className="custom-body-small text-gray-700">
          Fine-grained access control provides numerous features to help you
          keep your data secure. Features include document-level security,
          field-level security, read-only users, and OpenSearch
          Dashboards/Kibana tenants. Fine-grained access control requires a
          master user.
        </span>
        <div>
          <label className="flex cursor-pointer gap-2 my-3">
            <input
              type="checkbox"
              className="checkbox"
              name="enableFineGrainedAccessControl"
              checked={formData.enableFineGrainedAccessControl}
              onChange={handleInputChange}
            />
            <span>Enable fine-grained access control</span>
          </label>
        </div>

        <div>
          <label className="flex cursor-pointer gap-2 mt-3 custom-body-medium">
            Master User
          </label>
          <div className="my-1">
            <label className="flex cursor-pointer gap-2">
              <input
                type="radio"
                className="radio"
                checked={formData.masterUserOption === "IAM ARN"}
                onChange={() =>
                  handleInputChange({
                    target: {
                      name: "masterUserOption",
                      value: "IAM ARN",
                      type: "radio",
                    },
                  })
                }
              />
              <span>Set IAM ARN as master user</span>
            </label>
            <label className="flex cursor-pointer gap-2">
              <input
                type="radio"
                className="radio"
                checked={formData.masterUserOption === "Create master user"}
                onChange={() =>
                  handleInputChange({
                    target: {
                      name: "masterUserOption",
                      value: "Create master user",
                      type: "radio",
                    },
                  })
                }
              />
              <span>Create master user</span>
            </label>
          </div>
        </div>

        {formData.masterUserOption === "Create master user" && (
          <div>
            <label className="flex cursor-pointer gap-2 mt-3">
              Master Username
            </label>
            <input
              className="input rounded-none border max-w-md"
              type="text"
              name="masterUsername"
              value={formData.masterUsername}
              onChange={handleInputChange}
            />
            <label className="flex cursor-pointer gap-2 mt-3">
              Master Password
            </label>
            <input
              className="input rounded-none border max-w-md"
              type="password"
              name="masterPassword"
              value={formData.masterPassword}
              onChange={handleInputChange}
            />
          </div>
        )}

        {formData.masterUserOption === "IAM ARN" && (
          <div>
            <label className="flex cursor-pointer gap-2 mt-3">IAM ARN</label>
            <input
              className="input rounded-none border max-w-md"
              placeholder="arn:<partition>:iam::<account>:<type>/<id>"
              type="text"
              id="iamArn"
              name="iamArn"
              value={formData.iamArn}
              onChange={handleInputChange}
            />
          </div>
        )}
      </div>
      <div className="AccessPolicy-class border-2">
        <h4 className=" custom-body-large-bold">Access Policy</h4>
        <span className="custom-body-small">
          Access policies control whether a request is accepted or rejected when
          it reaches the Amazon OpenSearch Service domain. If you specify an
          account, user, or role in this policy, you must sign your requests.
        </span>

        <div className="border p-2 m-2">
          <h4>Domain Access Policy</h4>
          <label class="flex cursor-pointer gap-2">
            <input
              type="radio"
              className="radio"
              checked={formData.domainAccessPolicy === "fine-grained"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "domainAccessPolicy",
                    value: "fine-grained",
                    type: "radio",
                  },
                })
              }
            />
            <span>Only us fine-grained access control</span>
          </label>
          <label class="flex cursor-pointer gap-2">
            <input
              type="radio"
              className="radio"
              checked={formData.domainAccessPolicy === "domain-level"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "domainAccessPolicy",
                    value: "domain-level",
                    type: "radio",
                  },
                })
              }
            />
            <span>Do not set domain level access policy</span>
          </label>
          <label class="flex cursor-pointer gap-2">
            <input
              type="radio"
              className="radio"
              checked={formData.domainAccessPolicy === "configure-domain"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "domainAccessPolicy",
                    value: "configure-domain",
                    type: "radio",
                  },
                })
              }
            />
            <span>Configure domain level access policy</span>
          </label>
        </div>
      </div>
      <div className="Encryption border-2 p-2">
        <div className="custom-body-large-bold">Encryption</div>
        <div className="border p-2">
          <label class="flex cursor-pointer gap-2">
            <input
              type="radio"
              className="radio"
              checked={formData.encryptionKey === "aws-owned"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "encryptionKey",
                    value: "aws-owned",
                    type: "radio",
                  },
                })
              }
            />
            <span>Use an AWS owned key</span>
          </label>
          <label class="flex cursor-pointer gap-2">
            <input
              type="radio"
              className="radio"
              checked={formData.encryptionKey === "different-key"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "encryptionKey",
                    value: "different-key",
                    type: "radio",
                  },
                })
              }
            />
            <span>Choose a different AWS KMS key(advanced)</span>
          </label>
        </div>
      </div>
      <div className="Offpeak-class border-2 p-2">
        <div className="custom-body-large-bold">Off-peak Window</div>
        <span className="custom-body-small">
          Off-peak window is a 10-hour time block that you can use to schedule
          service software updates and auto-tune optimizations. While these
          updates are designed to be non-disruptive, the service uses the
          off-peak window to deploy updates at a comparatively lower traffic
          time, whenever possible. Please note that updates to the domain such
          as node replacements, or changes that need to be deployed to the
          domain immediately do not use the off-peak window.
        </span>
        <div className="border p-2 flex flex-col ">
          Startime(UTC)
          <input className="input mt-2" placeholder="00:00" type="time" />
        </div>
      </div>
      <div className="Autotune-class border-2 p-2">
        <div className="custom-body-large-bold">Auto-Tune</div>
        <span className="custom-body-small">
          Auto-Tune analyzes cluster performance over time and suggests
          optimizations based on your workload. You can choose to deploy these
          changes or rollback to the default OpenSearch settings at any time.
        </span>
        <div className="border p-2 flex flex-col ">
          Auto-Tune
          <div className="border p-2 my-2">
            <label class="flex cursor-pointer gap-2">
              <input
                type="radio"
                className="radio"
                checked={formData.autoTune === "off"}
                onChange={() =>
                  handleInputChange({
                    target: {
                      name: "autoTune",
                      value: "off",
                      type: "radio",
                    },
                  })
                }
              />
              <span>Turn off</span>
            </label>
            <span className="custom-body-small">
              No automated changes to your cluster, Amazon OpenSearch Service
              will still send notifications on how to optimize cluster
              performance.
            </span>
          </div>
          <div className="border p-2">
            <label class="flex cursor-pointer gap-2">
              <input
                type="radio"
                className="radio"
                checked={formData.autoTune === "on"}
                onChange={() =>
                  handleInputChange({
                    target: {
                      name: "autoTune",
                      value: "on",
                      type: "radio",
                    },
                  })
                }
              />
              <span>Turn on</span>
            </label>
            <span className="custom-body-small">
              Automatically makes node-level changes that require no downtime,
              such as tuning queues and cache sizes.
            </span>
          </div>
        </div>
      </div>
      <div className="border p-2">
        <input
          type="checkbox"
          className="checkbox mx-2"
          name="softwareUpdate"
          id="softwareUpdate"
        />
        <label className="">Enable automatic software update</label>
      </div>
    </div>
  );
}

export default StandardCreate;
