// src/sub-component/EasyCreate.js
import React from "react";

function EasyCreate({ formData, handleInputChange }) {
  return (
    <div className="flex flex-col gap-3">
      <div className="border-2 p-2 gap-2">
        <h3 className="my-1 custom-body-large-bold">Engine Options</h3>
        <h4>Version</h4>
        <div className="dropdow w-1/4">
          <select
            className="select"
            name="engineVersion"
            value={formData.engineVersion}
            onChange={handleInputChange}
          >
            <option>OpenSearch_2.11</option>
            <option>OpenSearch_2.10</option>
            <option>OpenSearch_2.9</option>
            <option>OpenSearch_2.7</option>
            <option>OpenSearch_2.5</option>
            <option>OpenSearch_2.3</option>
            <option>OpenSearch_1.3</option>
          </select>
        </div>
      </div>
      <div className="border-2 p-2">
        <h3 className="my-1 custom-body-large-bold">Network</h3>
        <div className="my-2">
          <label className="flex cursor-pointer gap-2">
            <input
              type="radio"
              className="radio"
              checked={formData.networkAccess === "VPC"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "networkAccess",
                    value: "VPC",
                    type: "radio",
                  },
                })
              }
            />
            <span>VPC access - recommended</span>
          </label>
          <label className="flex cursor-pointer gap-2">
            <input
              type="radio"
              className="radio"
              checked={formData.networkAccess === "Public"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "networkAccess",
                    value: "Public",
                    type: "radio",
                  },
                })
              }
            />
            <span>Public access</span>
          </label>
        </div>
        <p className="custom-body-medium">IP address type</p>
        <span className="custom-body-small text-gray-700">
          Select the type of IP address that your subnets use.
        </span>
        <div className="grid sm:grid-cols-2 my-2">
          <div className="flex border p-2 gap-2 mr-2">
            <input
              type="radio"
              className="radio-solid radio"
              checked={formData.ipAddressType === "Dual Stack"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "ipAddressType",
                    value: "Dual Stack",
                    type: "radio",
                  },
                })
              }
            />
            <span className="w-full">Dual Stack Mode</span>
          </div>
          <div className="flex border p-2 gap-2">
            <input
              type="radio"
              className="radio-solid radio"
              checked={formData.ipAddressType === "IPv4 only"}
              onChange={() =>
                handleInputChange({
                  target: {
                    name: "ipAddressType",
                    value: "IPv4 only",
                    type: "radio",
                  },
                })
              }
            />
            <span className="w-full">IPv4 only</span>
          </div>
        </div>
        <p className="custom-body-medium">VPC</p>
        <span className="custom-body-small text-gray-700">
          To use dual-stack, make sure that you associate an IPv6 CIDR block
          with a subnet in the VPC you specify.
        </span>
        <div className="my-2">
          <select className="select">
            <option>VPC ID</option>
          </select>
        </div>
      </div>
      {/* Additional fields for Easy Create can be added here */}
      <div className="border-2 p-2">
        <h3 className="my-1 custom-body-large-bold">
          Fine-grained access control
        </h3>
        <span className="custom-body-small text-gray-700">
          Fine-grained access control provides numerous features to help you
          keep your data secure. Features include document-level security,
          field-level security, read-only users, and OpenSearch
          Dashboards/Kibana tenants. Fine-grained access control requires a
          master user.
        </span>
        <div>
          <label className="flex cursor-pointer gap-2 my-3">
            <input
              type="checkbox"
              className="checkbox"
              name="enableFineGrainedAccessControl"
              checked={formData.enableFineGrainedAccessControl}
              onChange={handleInputChange}
            />
            <span>Enable fine-grained access control</span>
          </label>
        </div>

        <div>
          <label className="flex cursor-pointer gap-2 mt-3 custom-body-medium">
            Master User
          </label>
          <div className="my-1">
            <label className="flex cursor-pointer gap-2">
              <input
                type="radio"
                className="radio"
                checked={formData.masterUserOption === "IAM ARN"}
                onChange={() =>
                  handleInputChange({
                    target: {
                      name: "masterUserOption",
                      value: "IAM ARN",
                      type: "radio",
                    },
                  })
                }
              />
              <span>Set IAM ARN as master user</span>
            </label>
            <label className="flex cursor-pointer gap-2">
              <input
                type="radio"
                className="radio"
                checked={formData.masterUserOption === "Create master user"}
                onChange={() =>
                  handleInputChange({
                    target: {
                      name: "masterUserOption",
                      value: "Create master user",
                      type: "radio",
                    },
                  })
                }
              />
              <span>Create master user</span>
            </label>
          </div>
        </div>

        {formData.masterUserOption === "Create master user" && (
          <div>
            <label className="flex cursor-pointer gap-2 mt-3">
              Master Username
            </label>
            <input
              className="input rounded-none border max-w-md"
              type="text"
              name="masterUsername"
              value={formData.masterUsername}
              onChange={handleInputChange}
            />
            <label className="flex cursor-pointer gap-2 mt-3">
              Master Password
            </label>
            <input
              className="input rounded-none border max-w-md"
              type="password"
              name="masterPassword"
              value={formData.masterPassword}
              onChange={handleInputChange}
            />
          </div>
        )}

        {formData.masterUserOption === "IAM ARN" && (
          <div>
            <label className="flex cursor-pointer gap-2 mt-3">IAM ARN</label>
            <input
              className="input rounded-none border max-w-md"
              placeholder="arn:<partition>:iam::<account>:<type>/<id>"
              type="text"
              id="iamArn"
              name="iamArn"
              value={formData.iamArn}
              onChange={handleInputChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default EasyCreate;
