import React, { useState, useEffect } from "react";
import axios from "axios"; // Assuming use of axios for HTTP requests
import QuicksightEmbedding from "amazon-quicksight-embedding-sdk";

const Dashboard3 = ({ selectedDashboard }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEmbedded, setIsEmbedded] = useState(false);
  useEffect(() => {
    const fetchAndEmbedDashboard = async () => {
      setLoading(true);
      // console.log(`called when ${selectedDashboard}`);

      try {
        console.log("inside try");
        const response = await axios
          .get(
            // `https://y8887i4wj6.execute-api.us-east-1.amazonaws.com/dev?dashboard=dashboard3`
            `https://oqbkdxt815.execute-api.us-east-1.amazonaws.com/test?dashboard=dashboard3`
          )
          .then(console.log(`got the dashboard ${selectedDashboard}`));
        const responseBody = await JSON.parse(response.data.body);
        const embeddedURL = await responseBody.url;
        await embedDashboard(embeddedURL);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (!isEmbedded) {
      fetchAndEmbedDashboard();
    }
  }, [selectedDashboard, isEmbedded]);
  const embedDashboard = async (embeddedURL) => {
    const containerDiv = document.getElementById("dashboardContainer");
    if (!containerDiv) {
      console.error('Element with ID "dashboardContainer" not found');
      return;
    }

    const frameOptions = {
      url: embeddedURL,
      container: containerDiv,
      height: "850px",
      width: "100%",
      resizeHeightOnSizeChangedEvent: true,
    };

    const embeddingContext = await QuicksightEmbedding.createEmbeddingContext();
    embeddingContext.embedDashboard(frameOptions);
    setIsEmbedded(true);
  };

  return (
    <div>
      {loading && <p className="custom-body-large">Loading dashboard...</p>}
      {error && <p>Error loading dashboard: {error.message}</p>}
      <div id="dashboardContainer"></div>
    </div>
  );
};

export default Dashboard3;
